import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'

import './Consulting.scss';

function Consulting() {
	return (
		<main>
			<section className="band title">
				<div className="flx box">
					<div className="cell">
						<h6><span>Human Resource Planning</span>, Organisational Re Alignment And <span>Performance Management</span>, Culture</h6>					
					</div>
				</div>
			</section>
			<section className="band gap-y first last">
				<div className="flx box">
					<div className="cell">
						<h1><span>Management</span> Consulting</h1>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-col flx-order-2nd-in-column ">
						<h2 className="mt-0">Creating Organisational Synergy</h2>		
						<p>Our management experience will help you align your staff and your business goals to achieve the potential you have identified. 
						We have experience in small to large businesses across a range of industry sectors. You can rely on our staff leadership skills.</p>
						<ul>
						<li>Business Coaching</li>
						<li>Strategy Review and Alignment</li>
						<li>People Engagement</li>
						<li>Business Process Improvement</li>
						<li>Organisational Restructuring</li>
						</ul>
						<p>Call us to discuss your challenges and how we can assist you.</p>
						<CallToAction ctaLabel={["Ask how to build a better team"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
					<div className="cell flx-col management-consulting-img" />
				</div>
			</section>
		</main>
	);
}

export default Consulting;