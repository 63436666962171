import React from 'react';
import { Link } from 'react-router-dom'

import './Sitemap.scss';

function Sitemap() {
	console.log('test');
	return (
		<main>
			<section className="band title">
				<div className="flx box">
					<div className="cell">
						<h6><span>Links to everything</span> on our website.</h6>					
					</div>
				</div>
			</section>
			
			<section className="band gap-y first last">
				<div className="flx box">
					<div className="cell">
						<h1><span>Sitemap</span></h1>					
					</div>
				</div>
				<div className="flx box sitemap">
					<Link to="/">Homepage</Link>
					<Link to="/services/">Services</Link>
					<Link to="/cfo-services/">CFO Services</Link>
					<Link to="/management-consulting/">Management Consulting</Link>
					<Link to="/corporate-governance/">Corporate Governance</Link>
					<Link to="/about-me/">About Me</Link>
					<Link to="/contact/">Contact Form and Map</Link>
					<Link to="/privacy-policy/">Privacy Policy</Link>
					<Link to="/sitemap/">Sitemap (you are here now)</Link>
				</div>
			</section>
		</main>
	);
}

export default Sitemap;