import React from 'react';
import { Link } from 'react-router-dom';
//import { ReactComponent as IconBuildingOL } from "../../svg/icon-building-ol.svg";
import { ReactComponent as IconLnrGrowth } from "../../svg/icons/lnr-growth.svg";
import { ReactComponent as IconLnrCity } from "../../svg/icons/lnr-city.svg";
import { ReactComponent as IconLnrArchery } from "../../svg/icons/lnr-archery.svg";
import CustomLink from "../../utils/CustomLink.js";
import './Teasers.scss';

export default function Teasers(props) {

	const classes = [ 'box', 'flx', 'showcase' ];
	if (props.serviceSubMenu) {
		classes.push('service-sub-menu');
	}

	const teaserItems = [
		// {	key:		'IconLnrGrowth',
		// 	coreService:true,
		// 	icon:		"IconLnrGrowth",
		// 	heading:	[ <span key="1">CFO </span>, " Services" ],
		// 	linkLabel:	"To find out more, get in touch.",
		// 	LinkURL:	"/cfo-services/",
		// 	para:		"Are your you challenged by your current financial situation? Are you meeting the financial returns you expected to? "+
		// 				"We can work with you to improve your financial performance, improve your shareholder returns  and restructure your commitments."
		// 				//A Comprehensive Approach
		// },
		// {	key:		'IconLnrCity',
		// 	coreService:true,
		// 	icon:		"IconLnrCity",
		// 	heading:	[ <span key="2">MANAGEMENT </span>, " CONSULTING" ],
		// 	linkLabel:	"Click here to find out more.",
		// 	LinkURL:	"/management-consulting/",
		// 	para:		"Is your management team not aligned?  Do you need an experienced consultant to re engage your team and meet "+
		// 				"business challenges? We can work with you to develop your management structure and processes to align with your strategy. "
		// 				//"improve staff engagement and support business growth.  "
		// 				//Experience That Counts
		// },
		// {	key:		'IconLnrArchery',
		// 	coreService:false,
		// 	icon:		"IconLnrArchery",
		// 	heading:	[ <span key="3">Corporate  </span>, " Governance " ],
		// 	linkLabel:	"The Path to Success",
		// 	LinkURL:	"/corporate-governance/",
		// 	para:		"Do you want to engage your shareholders to share your company vision? We have experience in creating, "+
		// 				"guiding and chairing organizational boards to make sure they give strong strategic advice that will engage with shareholders."

		// }
		{	key:		'IconLnrGrowth',
			coreService:true,
			icon:		"IconLnrGrowth",
			heading:	[ <span key="1">CFO </span>, " Services" ],
			linkLabel:	"Click here for more on CFO Services.",
			LinkURL:	"/cfo-services/",
			para:		"We provide a wide range of services to ensure that your business is performing strongly. "+
						"We can work with you throughout all business cycles leveraging our experience in a number "+
						"of industry verticals and across all financial performance metrics. "//+
						// "Financial Performance Management, "+
						// "Business Planning "
						// "Cashflow Management "+
						// "Profit and Growth improvement "+
						// "Working Capital Management "+
						// "Recapitalisation "+
						// "Accounting System Development "+
						// "Call us to discuss your challenges and how we can assist you."
		},
		{	key:		'IconLnrCity',
			coreService:true,
			icon:		"IconLnrCity",
			heading:	[ <span key="2">MANAGEMENT </span>, " CONSULTING" ],
			linkLabel:	"Click here to find out more.",
			LinkURL:	"/management-consulting/",
			para:		"Our management experience will help you align your staff and your business goals to achieve the potential "+
						"you have identified. We have experience in small to large businesses across a range of industry sectors. "+
						"You can rely on our staff leadership skills. "						
		},
		{	key:		'IconLnrArchery',
			coreService:false,
			icon:		"IconLnrArchery",
			heading:	[ <span key="3">Corporate  </span>, " Governance " ],
			linkLabel:	"Call us to discuss how we can assist.",
			LinkURL:	"/corporate-governance/",
			para:		"We have a broad experience in board management and operation. We can help you setup an advisory "+
						"board to provide guidance to your management team and guide your business growth. "+
						"Call us to discuss your challenges and how we can assist you."

		}
	];

	const teaserContent = teaserItems.length ? teaserItems.map(teaserItem =>
		(teaserItem.coreService || !props.serviceSubMenu) &&
			<CustomLink
				tag='div'
				to={teaserItem.LinkURL}
				className="showcase-panel flx-col"
				key={teaserItem.key}
				>
				<div className="icon-box">
					{ teaserItem.icon === 'IconLnrGrowth' &&<IconLnrGrowth /> }
					{ teaserItem.icon === 'IconLnrCity' &&<IconLnrCity /> }
					{ teaserItem.icon === 'IconLnrArchery' &&<IconLnrArchery /> }
				</div>
				<div className="text-box">
					<h2>{teaserItem.heading}</h2>
					<p>{teaserItem.para}</p>
					<Link to={teaserItem.LinkURL} >{teaserItem.linkLabel}</Link>
				</div>
			</CustomLink>
	) : '...';

	return (
		<section className="band white-bg">
			<div className={classes.join(' ')}>
				{teaserContent}
			</div>
		</section>
	)
};