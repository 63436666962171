import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'

import './AboutMe.scss';

/*pb-6 my-3*/
function AboutMe() {
	return (
		<main>
			<section className="band title">
				<div className="flx box">
					<div className="cell">
						<h6><span>Delivering</span> a personal and <span>meaningful contribution</span></h6>					
					</div>
				</div>
			</section>
			<section className="band gap-y first last">
				<div className="flx box">
					<div className="cell">
						<h1><span>About</span> Faithfull Business Advisory</h1>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-col about-us-img" />
					<div className="cell flx-col">
						<h2 className="mt-0">Robert Faithfull</h2>		
						<p>I am passionate about working with enterprises to solve your organisational and financial challenges. I have 20 years’ executive experience in a broad cross section of industries including manufacturing, distribution, retail, not for profit and agricultural sectors.  My experience has been gained in regional metropolitan and international business contexts.</p>
						<p>I am a Fellow of CPA Australia and hold a Masters of Business Administration from Deakin University and I have experience as a chief financial officer, company secretarial and directors’ roles in not for profit organisations. </p>
						<p>I’d love to hear from you, hear your story and discuss how I can help you solve your challenges.</p>
						<CallToAction ctaLabel={["Ask How I Can Help You Succeed"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
				</div>
			</section>
		</main>
	);
}

export default AboutMe;