import React from 'react';
import Teasers from '../../components/Teasers/Teasers.js';

function Home(props) {
  return (



	<main>

		<section className="band title">
			<div className="flx box">
				<div className="cell">
					<h1><span>Services</span> </h1>					
				</div>
			</div>
		</section>
		
		<Teasers />

	</main>
  );
}
export default Home;