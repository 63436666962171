import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Slider.scss";
import CallToAction from "../CallToAction/CallToAction";

export default function Slider(props){	
	const [pause, setPause] = React.useState(false);
	const timer = React.useRef();
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const [sliderRef, slider] = useKeenSlider({
		loop: true,
		duration: 2000,
		initial: props.initialSlide,
		autoHeight: false,
		dragStart: () => {
			setPause(true);
		},
		dragEnd: () => {
			setPause(false);
		},
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
 	 });

	React.useEffect(() => {
		sliderRef.current.addEventListener("mouseover", () => {
			setPause(true);
		});
		sliderRef.current.addEventListener("mouseout", () => {
			setPause(false);
		});
	}, [sliderRef]);

	React.useEffect(() => {
		timer.current = setInterval(() => {
			if (!pause && slider) {
				slider.next();
			}
		}, process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? 500000 : 7000 );
		return () => {
			clearInterval(timer.current);
		};
	}, [pause, slider, currentSlide]);

//console.log(currentSlide);
	return (
		<>
		<div className="navigation-wrapper">
			<div ref={sliderRef} className="keen-slider keen-slider--banner">
			<div className={`keen-slider__slide slider-image-background slider-01 ${currentSlide===0 ? 'active' : ''}`}>
				<h2>Helping You Build a <span>Better Business</span></h2>
				<h3>Realise The Potential of Your Organisation</h3>
				<CallToAction className="cta--contrast" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			<div className={`keen-slider__slide slider-image-background slider-02 ${currentSlide===1 ? 'active' : ''}`}>
				<h2>Leverage Our <span>Expertise</span></h2>
				<h3>Access Over 20 Years of Experience </h3>
				<CallToAction className="cta--support" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			<div className={`keen-slider__slide slider-image-background slider-03 ${currentSlide===2 ? 'active' : ''}`}>
				<h2>Working Closely With Clients for <span>Better Outcomes</span></h2>
				<h3>We Are Dedicated to Your Success</h3>
				<CallToAction className="cta--support" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			<div className={`keen-slider__slide slider-image-background slider-06 ${currentSlide===3 ? 'active' : ''}`}>
				<h2>Working Closely With Clients for <span>Better Outcomes</span></h2>
				<h3>In The City</h3>
				<CallToAction className="cta--contrast" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			<div className={`keen-slider__slide slider-image-background slider-04 ${currentSlide===4 ? 'active' : ''}`}>
				<h2>We Take Time to Understand <span>Your Business Needs</span></h2>
				<h3>In The Country</h3>
				<CallToAction className="cta--support" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			<div className={`keen-slider__slide slider-image-background slider-05 ${currentSlide===5 ? 'active' : ''}`}>
				<h2>Helping You Build a <span>Better Business</span></h2>
				<h3>We Will Come to You</h3>
				<CallToAction className="cta--contrast" ctaLabel='Ask Us How' ctaDestination='/contact/'></CallToAction>
			</div>
			</div>
			{slider && (
			<>
				<ArrowLeft
				onClick={e => e.stopPropagation() || slider.prev()}
				//disabled={currentSlide === 0}
				/>

				<ArrowRight
				onClick={e => e.stopPropagation() || slider.next()}
				//  disabled={currentSlide === slider.details().size - 1}
				/>
			</>
			)}
		</div>
		{slider && (
			<div className="dots">
			{[...Array(slider.details().size).keys()].map(idx => {
				return (
				<button
					key={idx}
					onClick={() => {
					slider.moveToSlideRelative(idx);
					}}
					aria-label={`Banner ${idx}`}
					className={"dot" + (currentSlide === idx ? " active" : "")}
				/>
				);
			})}
			</div>
		)}
		</>
	);
};

function ArrowLeft(props) {
	const disabeld = props.disabled ? " arrow--disabled" : "";
	return (
		<svg
			onClick={props.onClick}
			className={"arrow arrow--left" + disabeld}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			>
			<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
		</svg>
	);
}

function ArrowRight(props) {
	const disabeld = props.disabled ? " arrow--disabled" : "";
	return (
		<svg
			onClick={props.onClick}
			className={"arrow arrow--right" + disabeld}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			>
			<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
		</svg>
	);
}
