import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import Logo from '../Logo';
import Banner from '../../components/Banner/Banner.jsx';
import Menu from '../Menu/Menu';
import './Header.scss';

// see https://github.com/reactjs/react-transition-group/tree/v1-stable for future transitions

function Header({isHamburger, location, sliderStart, pageIsNarrow}) {

	return (
		<>
			<div id="header" className="header">
				<div id="header-box" className="box header-box">
					<div className="header-item header-item--brand">
						<Link to='/'><Logo /></Link>
					</div>
					<div className="header-item header-item--nav">					
						<div className='menu-box'>
							<div className='menu-liner'>
								<Menu isHamburger={isHamburger}  />
							</div>
						</div>
					</div>

				</div>
			</div>
			<Banner pathName={location.pathname} sliderStart={sliderStart} />
		</>
	);
}
export default withRouter(Header);