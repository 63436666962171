import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'

import './CFOServices.scss';

function CFOServices() {
	return (
		<main>
			<section className="band title">
				<div className="flx box">
					<div className="cell">
						<h1><span>Chief Financial Officer</span> and Executive Officer</h1>					
					</div>
				</div>
			</section>
			
			<section className="band gap-y first last">
				<div className="flx box">
					<div className="cell">
						<h6><span>CFO</span> Services</h6>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-col cfo-services-img" />
					<div className="cell flx-col">
						<h2 className="mt-0">Experienced CFO</h2>		

						<p>
							We provide a wide range of services to ensure that your business is performing strongly.
							We can work with you throughout all business cycles leveraging our experience in a number of industry verticals and across such specialities as;
						</p> 
						<ul>
						<li>Financial Performance Management</li>
						<li>Business Planning</li>
						<li>Cashflow Management</li>
						<li>Profit and Growth improvement</li>
						<li>Working Capital Management</li>
						<li>Recapitalisation</li>
						<li>Accounting System Development</li>
						</ul>
						<p>Call us to discuss your challenges and how we can assist you.</p>

						<CallToAction ctaLabel={["Email or Call us for a Chat"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
				</div>
			</section>
		</main>
	);
}

export default CFOServices;