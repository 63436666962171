


const MenuItems = [
	{
		itemKey: 'services',
		label: 'Services',
		path: '/services/',
		clickable: false,
		openByDefault: true,
		subMenuItems: [
			{
				title: 'CFO Services',
				path: '/cfo-services/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item'
			},
			{
				title: 'Management Consulting',
				path: '/management-consulting/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item'
			},
			{
				title: 'Corporate Governance',
				path: '/corporate-governance/',
				linkClassName: 'sub-menu-link',
				itemClassName: 'sub-menu-item'
			}
		]
	},
	{
		itemKey: 'aboutMe',
		label: 'About Me',
		path: '/about-me/',
		clickable: true,
		subMenuItems: []
	},
	// {
	// 	itemKey: 'other',
	// 	label: 'Other',
	// 	path: '/services/',
	// 	clickable: false,
	// 	openByDefault: true,
	// 	subMenuItems: [
	// 		{
	// 			title: 'CFO Services',
	// 			path: '/cfo-services/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		},
	// 		{
	// 			title: 'Management Consulting',
	// 			path: '/management-consulting/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		},
	// 		{
	// 			title: 'Corporate Governance',
	// 			path: '/corporate-governance/',
	// 			linkClassName: 'sub-menu-link',
	// 			itemClassName: 'sub-menu-item'
	// 		}
	// 	]
	// },
	{
		itemKey: 'contact',
		label: 'Contact',
		path: '/contact/',
		clickable: true,
		subMenuItems: []
	}
]
export default MenuItems;