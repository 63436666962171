import React, { useState, useLayoutEffect, useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import HelmetWrap from '../utils/HelmetWrap.js'
import * as utils from '../utils/general.js';

import './App.scss';

import Header			from '../components/Header/Header';

import { useTracking }	from '../hooks/useTracking.js'
import Loader from '../components/Loader/Loader.jsx'

// import Home				from '../containers/Home/Home';
import Consulting		from '../containers/Consulting/Consulting';
import Governance		from '../containers/Governance/Governance';
import CFOServices		from '../containers/CFOServices/CFOServices';
import AboutMe			from '../containers/AboutMe/AboutMe';
// import Contact			from '../containers/Contact/Contact';
import Services			from '../containers/Services/Services';
import PrivacyPolicy	from '../containers/PrivacyPolicy/PrivacyPolicy';
import Sitemap			from '../containers/Sitemap/Sitemap';
import FourZeroFour		from '../containers/FourZeroFour/FourZeroFour';
// import Footer 			from '../components/Footer/Footer';
// import Scroller 		from '../components/Scroller/Scroller.js';

const Home = React.lazy(() => import('../containers/Home/Home'));
// const Consulting = React.lazy(() => import('../containers/Consulting/Consulting'));
// const Governance = React.lazy(() => import('../containers/Governance/Governance'));
// const CFOServices = React.lazy(() => import('../containers/CFOServices/CFOServices'));
// const AboutMe = React.lazy(() => import('../containers/AboutMe/AboutMe'));
const Contact = React.lazy(() => import('../containers/Contact/Contact'));
// const Services = React.lazy(() => import('../containers/Services/Services'));
// const PrivacyPolicy = React.lazy(() => import('../containers/PrivacyPolicy/PrivacyPolicy'));
// const Sitemap = React.lazy(() => import('../containers/Sitemap/Sitemap'));
// const FourZeroFour = React.lazy(() => import('../containers/FourZeroFour/FourZeroFour'));
const Footer = React.lazy(() => import('../components/Footer/Footer'));
const Scroller = React.lazy(() => import('../components/Scroller/Scroller'));


function App() {
	useTracking();
	useLayoutEffect(() => {
		window.addEventListener('scroll', handleHeader);
		return () => window.removeEventListener('scroll', handleHeader);
	});
	useLayoutEffect(() => {
		window.addEventListener('resize', resizeHandler);							// Run the rezise through a throttle
		return () => window.removeEventListener('resize', resizeHandler);
	});
	useEffect(() => {																// This is an onLoad to determine header if on mobile etc.
		handleHeader();
		handleBreakpoint();
	}, []);

	const [headerIsSmall, setHeaderIsSmall] = useState(     window.pageYOffset < 70 && window.innerWidth >= 480  ? false : true     ); // LIGHTHOUSE default header size to avoid Cumulative Layout Shift (CLS)
	const [pageScrolled, setPageScrolled] = useState(false);
	const [pageIsNarrow, setPageIsNarrow] = useState(false);
	const [isHamburger, setIsHamburger] = useState(false);
	const [breakpoint, setBreakpoint] = useState({ level: 70, label: 'desktop-m'});
	const [sliderStart] = useState(Math.floor(Math.random() * 6));

	const resizeHandler = utils.throttle(() => {
		window.dispatchEvent(new CustomEvent('RESIZE'));							// as we have hijacked the resize event - ignoring ultra short repeats, (re) trigger it manually
		handleHeader();
		handleBreakpoint();
	});

	const handleBreakpoint = () => {
		setBreakpoint({
			label: utils.getBreakpoint(),
			level: utils.breakpoints[utils.getBreakpoint()]
		});
	}

	const handleHeader = () => {
		// TASK: change to use refs
		if ( window.pageYOffset < 5 ) {
			document.getElementById('header').classList.remove('header--shadow');
		}
		if ( window.pageYOffset >= 5 ) {
			document.getElementById('header').classList.add('header--shadow');
		}
		if ( window.pageYOffset < 100 ) {
			setPageScrolled(false);
		}
		if ( window.pageYOffset >= 100 ) {
			setPageScrolled(true);
		}
		if ( window.innerWidth < 480 ) {
			setPageIsNarrow(true);
		}
		if ( window.innerWidth >= 480 ) {
			setPageIsNarrow(false);
		}

		if ( window.innerWidth < 880 ) { // SETTING--hamburger
			setIsHamburger(true);
		}
		if ( window.innerWidth >= 880 ) {
			setIsHamburger(false);
		}

		if ( window.pageYOffset >= 70 || window.innerWidth < 480 ) {
			setHeaderIsSmall(true);
		}
		if ( window.pageYOffset < 70 && window.innerWidth >= 480 ) {
			setHeaderIsSmall(false);
		}
	}

	const classes = [ 'App ' ];
	if (!headerIsSmall) {
		classes.push('header--big');
	}
	if (pageScrolled) {
		classes.push('page--scrolled');
	}
	if (pageIsNarrow) {
		classes.push('page--thin');
	}
	if (isHamburger) {
		classes.push('header--hamburger');
	}


	return (
		<div className={classes.join(' ')} id="app">
			<HelmetWrap />
			<Header isHamburger={isHamburger} sliderStart={sliderStart} />
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route
						exact
						path="/"
						render={routeProps => {
							return <Home {...routeProps} breakpoint={breakpoint} />; }
						}
						/>
					<Route path='/cfo-services/' component={CFOServices}/>
					<Route path='/management-consulting/' component={Consulting}/>
					<Route path='/corporate-governance/' component={Governance}/>
					<Route path='/about-me/' component={AboutMe}/>
					<Route
						path="/contact/"
						render={routeProps => {
							return <Contact {...routeProps} pageIsNarrow={pageIsNarrow} />; }
						}
						/>
					<Route path='/services/' component={Services}/>
					<Route path='/privacy-policy/' component={PrivacyPolicy}/>
					<Route path='/sitemap/' component={Sitemap}/>
					<Route path='*' component={FourZeroFour}/>
				</Switch>
				<Footer />
				<Scroller />
			</Suspense>
		</div>
	);
}

export default App;
