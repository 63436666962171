import React, { useEffect, useState } from 'react'; //useState, useEffect,

import { useLocation, Link } from 'react-router-dom'

import menuArray from './MenuItems';		// this just imports the array of menu items
import Dropdown from '../../utils/Dropdown'
import '../HamburgerMenu/HamburgerMenu.scss';
import '../NavBar/NavBar.scss';

function Menu({ isHamburger, ...rest}) {

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
	
	const location = useLocation();
	const pathname = location.pathname;
	
	useEffect(() => {
		setIsMenuOpen(false);
		setIsSubMenuOpen(false);
	}, [location]);

	const handleMenuToggle = (event) => {
		event.stopPropagation();
		isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
	}

	const handleSubMenuItemClick = (event) => {
		event.stopPropagation();
	}

	const handleClose = () => {
		setIsMenuOpen(false);
		setIsSubMenuOpen(false);
	}

	const BuildMenuItems = (props) => {
		return (
			<ul className='menu-panel'>			
				{ menuArray.map((iter, index) => {

					if (iter.subMenuItems && Array.isArray(iter.subMenuItems) && iter.subMenuItems.length > 0) {
						return (
							<Dropdown isHamburger={isHamburger} key={Math.random()} openByDefault={iter.openByDefault} {...iter} />
						)
					}
					const classes = [ 'menu-item' ];
					if (iter.itemClassName && iter.itemClassName.length) {
						classes.push(iter.itemClassName);
					}				
					if (pathname === iter.path) {
						classes.push('active');
					}				
					return (							
						<li key={index} className={classes.join(' ')}  >						
							<Link className={iter.linkClassName} to={iter.path} onClick={handleSubMenuItemClick}>
								{iter.label}
							</Link>
						</li>
					)								
				})}
			</ul>
		)
	}

	return (
		<>
			{/* OK, so this seems super hacky but we need to render both menus to allow traansitions */}

			<div className='hamburger-wrap'>
				<div className={`hamburger ${isHamburger && isMenuOpen ? "hamburger-open" : ""}`} id="hamburger" onClick={handleMenuToggle}>
					<div className="hamburger-lines"></div>
				</div>
				<div className={`hamburger-menu ${isHamburger && isMenuOpen ? "hamburger-menu--open" : ""}`} >
					{ menuArray && menuArray.length > 0 &&
						<BuildMenuItems name="burger" isHamburger={true}></BuildMenuItems> }
				</div>
			</div>

			<div className="nav-wrap">
				<div className="nav-menu">
					{ menuArray && menuArray.length > 0 &&
						<BuildMenuItems name="main" isHamburger={false}></BuildMenuItems>}
				</div>
			</div>

			{ (isMenuOpen || isSubMenuOpen) &&
				<div className={`menu-canvas ${isMenuOpen ? 'menu-canvas--active' : ''}`} onClick={handleClose} /> }
		</>
	)
}

export default Menu;

