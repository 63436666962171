import React  from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet-async';

const HelmetWrap = ({ page = 'home', location: { pathname } }) => {

	/*
		See copied content in Index.html (in the og tags)
	*/

	// default content for home page
	let metaTitle = 		'Business Advice to Build Growth | Faithfull Business Advisory';
	let metaDescription = 	'Do you have a project or idea that you’d love to bring to life? '+
							'Do you want to grow your business but not sure how to go about this? '+
							'I have experience to develop financial reporting models that will in '+
							'growing and develop your business  to achieve your goals. '+
							'To find out more, get in touch.';

	// default path for all pages OTHER THAN home and 404
	let canonicalUrl = 'https://faithfullba.com.au'+pathname; 

	switch (pathname) {
		
		case ('/'):
			//canonicalUrl = 'https://faithfullba.com.au';   REMOVED - Google treat them the same and in search console add the slash so let's mimic that.
			break;

		case '/about-me/':
			metaTitle = 		'Experienced CFO Can Help Your Business | Faithfull Business Advisory';
			metaDescription = 	'If you need help building a better business, you can leverage our experience. '+
								'I am a Fellow of CPA Australia and hold a Masters of Business Administration ' +
								'from Deakin University and I have experience as a chief financial officer, '+
								'company secretarial and directors’ roles in not for profit organisations. '+
								'To find out more, get in touch.';
			break;

		case '/contact/':
			metaTitle = 		'Talk to us. See how we can help you. | Faithfull Business Advisory';
			metaDescription = 	'We work closely with our clients to find strategic advantages and help them grow their business. ' +
								"It's easy to find out more. Get our contact details here...";
			break;

		case '/cfo-services/':
			metaTitle = 		'CFO and Executive Officer Help | Faithfull Business Advisory';
			metaDescription = 	'We provide a wide range of services to ensure that your business is well structured and performing strongly. ' +
								"Get in touch to find out how to leverage our experience for your benefit.";
			break;

		case '/management-consulting/':
			metaTitle = 		'Creating Organisational Synergy | Faithfull Business Advisory';
			metaDescription = 	'Our management experience will help you align your staff and your business ' +
								'goals to achieve the potential you have identified. We have experience in small ' +
								'to large businesses across a range of industry sectors. You can rely on our staff leadership skills.';
			break;

		case '/corporate-governance/':
			metaTitle = 		'Build a Better Executive Board | Faithfull Business Advisory';
			metaDescription = 	'We can help you setup an advisory board to provide guidance to your management team and guide your business growth.' +
								"Get in touch to find out how to leverage our experience for your benefit.";
			break;

		case '/services/':
			metaTitle = 		'Advice & Expertise to Help You Succeed | Faithfull Business Advisory';
			metaDescription = 	'We provide personal support to business growth leveraging a diverse background in accounting, ' +
								'finance and business management. Want to learn more? Contact us today!';
			break;

		case '/sitemap/':
			metaTitle = 		'Shortcut to Support Links and Resources | Faithfull Business Advisory';
			metaDescription = 	'Use our Sitemap to quickly get to our support links or any other content on our system. ' +
								'Want to learn more? Contact us now!';
			break;
			
		case '//':
			metaTitle = 		'Advice & Expertise to Help You Succeed | Faithfull Business Advisory';
			metaDescription = 	'We provide personal support to business growth leveraging a diverse background in accounting, ' +
								'finance and business management. Want to learn more? Contact us today!';
			break;
			
		case '/privacy-policy/':
			metaTitle = 		'Privacy Policy - We care about data safety | Faithfull Business Advisory';
			metaDescription = 	'Data you provide to us is considered sensitive and not shared. We know how important confidentaility is and treat your information with care and respect.';
			break;

		default:
			// 404
			metaTitle = 		'Oops! | Faithfull Business Advisory';
			metaDescription = 	'We had a look but we couldn\'t find the page you requested. Try the navigation at the top of the page or contact us if you need help.';
			canonicalUrl = 		'https://faithfullba.com.au/404/'; 
			break;
	}

	return (
		<Helmet>
			<title>{metaTitle}</title>
			<meta name="description" content={metaDescription} />
			<link rel="canonical" href={canonicalUrl} />
		</Helmet>
	)
}

export default withRouter(HelmetWrap);