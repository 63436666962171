import React from 'react';
import CallToAction from '../../components/CallToAction/CallToAction.js'

import './Governance.scss';

function Governance() {
	return (
		<main>
			<section className="band title">
				<div className="flx box">
					<div className="cell">
						<h1><span>Board Culture</span>, Process Management, Participation And <span>Performance</span></h1>	
					</div>
				</div>
			</section>
			<section className="band gap-y first last">
				<div className="flx box">
					<div className="cell">
						<h6><span>Corporate</span> Governance</h6>					
					</div>
				</div>
				<div className="flx box">
					<div className="cell flx-col corporate-governance-img" />
					<div className="cell flx-col">
						<h2 className="">Building a Better Board</h2>
						<p>
							We have a broad experience in board management and operation. We can help you setup an advisory  
							board to provide guidance to your management team and guide your business growth.
						</p>
						<ul>
							<li>Governance Review and Advisory</li>
							<li>Business Risk Review</li>
							<li>Effective Board Processes and Protocols</li>
						</ul>
						<p>Call us to discuss your challenges and how we can assist you.</p>
						<CallToAction ctaLabel={["Email or Call us for a Chat"]} className="cta--long cta--contrast" ctaDestination='/contact/'/>	
					</div>
				</div>
			</section>
		</main>
	);
}

export default Governance;