import React from "react";

import Slider from './Slider.js'
//import CallToAction from '../../components/CallToAction/CallToAction.js'
import './Banner.scss';
//import { Redirect } from "react-router-dom";

const getBannerContent = ({ className, pathName, sliderStart }) => {

	const classes = [ 'banner' ];
	if (className) {
		classes.push(className);
	}

	if(pathName === '/') {
		classes.push('banner--slider');
		return (
			<>
				<section className={classes.join(' ')}>
					<Slider initialSlide={sliderStart} />
				</section>
			</>
		);
	}

	// if(pathName === 'xxxx/about-me/') {
	// 	classes.push('banner--billboard billboard--in-the-field banner--base');
	// 	return (
	// 		<>
	// 			<section className={classes.join(' ')}>
	// 				{/* <div className="container row"> */}
	// 					<div className="teaser-box teaser-box--contained">
	// 						<div className="teaser">
	// 							<h2>Personal Experience</h2>
	// 							<p>We can help you build a better business.</p>
	// 							<CallToAction className="cta--rpf-dark-blue " ctaLabel="Ask how we can help you" />
	// 						</div>
	// 					</div>
	// 				{/* </div> */}
	// 			</section>
	// 		</>
	// 	);
	// }

	if(pathName === '/contact/') {
		classes.push('banner--map');
		return (
			<section className={classes.join(' ')}> </section>
		);
	}

	classes.push('banner--blank');
	//if(pathName == '/corporate-governance/' || pathName === '/cfo-services/') {
		classes.push('title-bg');
	//}
	return (
		<section className={classes.join(' ')}> </section>
	);
}

function Banner(props){
	const content = getBannerContent(props);
	return content;
}
export default Banner;